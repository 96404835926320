


import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import SubscriptionForm from "./SubscriptionForm";
import { withRouter } from 'react-router-dom';

const SubscriptionComponent = () => (
   
  <ElementsConsumer>
    {({stripe, elements}) => (
            <SubscriptionForm stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default withRouter(SubscriptionComponent);