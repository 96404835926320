import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import RegisterComponent from "../Auth/Register";
import { withRouter } from 'react-router-dom';

const PaymentDetails = () => (
   
  <ElementsConsumer>
    {({stripe, elements}) => (
            <RegisterComponent stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default withRouter(PaymentDetails);