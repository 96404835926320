import { useState, useRef, useEffect } from 'react'

const PADDINGS = 110;

const useSliding = (elementWidth, countElements, children) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [distance, setDistance] = useState(0);
  const [totalInViewport, setTotalInViewport] = useState(0)
  const [viewed, setViewed] = useState(0);
  const [contents, setContents] = useState(children);

  const contLength = children.length;

  // alert(contLength);

  useEffect(() => {
    const containerWidth = containerRef.current.clientWidth - PADDINGS;

    setContainerWidth(containerWidth);
    setTotalInViewport(Math.floor(containerWidth / elementWidth));
  }, [containerRef.current]);

  const handlePrev = () => {
    setViewed(viewed - totalInViewport);
    setDistance(distance + containerWidth);
  }

  const handleNext = () => {
    setViewed(viewed + totalInViewport);
    setDistance(distance - containerWidth)
    
     // to loop the contents
    if ((viewed + 10) > contents.length) {
      for (var i = 0; i < contLength; i++) {
        contents.push(children[i]);
      }
    }

     setContents(contents);

  }

  const slideProps = {
    style: { transform: `translate3d(${distance}px, 0, 0)` }
  };

  const hasPrev = distance < 0;
  // const hasNext = (viewed + totalInViewport) < countElements;
  const hasNext = (viewed + totalInViewport) ;

  return { handlePrev, handleNext, slideProps, containerRef, hasPrev, hasNext, contents };
}

export default useSliding;