


import React from 'react';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CardDetailsComponent from "../User/Settings/CardDetails";
import { withRouter } from 'react-router-dom';

const NewCardDetails = () => (
   
  <ElementsConsumer>
    {({stripe, elements}) => (
            <CardDetailsComponent stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default withRouter(NewCardDetails);